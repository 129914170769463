import { FormControl, IconButton } from '@mui/material';
import styles from './CommunityLinkModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { useState } from 'react';
import { LinkAvatar } from '../LinkAvatar/LinkAvatar';
import { AppInput } from '../../../../components/ui/AppInput/AppInput';
import { Formik } from 'formik';
import { FieldError } from '../../../../components/ui/FieldError/FieldError';

export const CommunityLinkModal = ({
  link,
  onClose,
  onSave,
  pos = [200, 200],
}) => {
  const [avatar, setAvatar] = useState(link?.avatar || null);
  const { title, url } = link || {};

  console.log('POS', pos);

  const isEdit = !!link;

  const submitForm = (data) => {
    onSave({
      avatar,
      title: data.title,
      url: data.url,
    });
    onClose();
  };

  const validateForm = (data) => {
    const errors = {};
    const { title, url } = data;

    if (!avatar) {
      errors.avatar = 'Загрузите обложку';
    }

    if (!title) {
      errors.title = 'Введите название';
    } else if (title.length < 2 || title.length > 20) {
      errors.title = 'Название должно быть от 2 до 20 символов';
    }

    if (!url) {
      errors.url = 'Введите ссылку';
    } else if (
      !/^(https?:\/\/)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i.test(
        url
      )
    ) {
      errors.url = 'Некорректная ссылка';
    } else if (url.length > 78) {
      errors.url = 'Ссылка не может быть длиннее 78 символов';
    }

    return errors;
  };

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.title}>
          {isEdit ? `Редактирование ссылки` : 'Новая ссылка'}
        </div>
        <div className={styles.close}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <div className={styles.content}>
        <Formik
          initialValues={{
            title,
            url,
          }}
          validate={validateForm}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.section}>
                <div className={styles.sectionHeader}>Обложка</div>
                <div className={styles.sectionContent}>
                  <LinkAvatar
                    url={
                      link
                        ? `${process.env.REACT_APP_API_URL}communities/links/${link.id}/avatar?${link.avatar}`
                        : null
                    }
                    onChange={setAvatar}
                  />
                  {!!errors.avatar && <FieldError>{errors.avatar}</FieldError>}
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>Название</div>
                <div className={styles.sectionContent}>
                  <FormControl fullWidth>
                    <AppInput
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      placeholder="Введите название"
                      onBlur={handleBlur}
                      error={!!(touched.title && errors.title)}
                    />
                  </FormControl>

                  {touched.title && !!errors.title ? (
                    <FieldError>{errors.title}</FieldError>
                  ) : (
                    <div className={styles.inputDescription}>
                      Не может содержать более 20 символов
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>Ссылка</div>
                <div className={styles.sectionContent}>
                  <FormControl fullWidth>
                    <AppInput
                      name="url"
                      value={values.url}
                      placeholder="Введите ссылку"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(touched.url && errors.url)}
                    />
                  </FormControl>
                  {touched.url && !!errors.url && (
                    <FieldError>{errors.url}</FieldError>
                  )}
                </div>
              </div>

              <div className={styles.footer}>
                <AppButton color="secondary" onClick={onClose}>
                  Отмена
                </AppButton>
                &nbsp; &nbsp;
                <AppButton type="submit" disabled={isSubmitting}>
                  {isEdit ? 'Сохранить' : 'Добавить'}
                </AppButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
