import styles from './SimpleOptions.module.scss';

export const SimpleOptions = ({ options, value, onChange }) => {
  const optionClicked = (option) => {
    onChange && onChange(option.name);
  };

  return (
    <span className={styles.options}>
      {options.map((option) => (
        <span
          className={styles.option}
          data-active={option.name === value ? 'active' : ''}
          key={option.name}
          onClick={() => optionClicked(option)}
        >
          {option.title}
        </span>
      ))}
    </span>
  );
};
