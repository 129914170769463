export const PhotoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.2501C12.1989 10.2501 12.3897 10.3291 12.5303 10.4698C12.671 10.6104 12.75 10.8012 12.75 11.0001V12.2501H14C14.1989 12.2501 14.3897 12.3291 14.5303 12.4698C14.671 12.6104 14.75 12.8012 14.75 13.0001C14.75 13.199 14.671 13.3898 14.5303 13.5305C14.3897 13.6711 14.1989 13.7501 14 13.7501H12.75V15.0001C12.75 15.199 12.671 15.3898 12.5303 15.5305C12.3897 15.6711 12.1989 15.7501 12 15.7501C11.8011 15.7501 11.6103 15.6711 11.4697 15.5305C11.329 15.3898 11.25 15.199 11.25 15.0001V13.7501H10C9.80109 13.7501 9.61032 13.6711 9.46967 13.5305C9.32902 13.3898 9.25 13.199 9.25 13.0001C9.25 12.8012 9.32902 12.6104 9.46967 12.4698C9.61032 12.3291 9.80109 12.2501 10 12.2501H11.25V11.0001C11.25 10.8012 11.329 10.6104 11.4697 10.4698C11.6103 10.3291 11.8011 10.2501 12 10.2501Z"
        fill="#96ABC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.778 21.0001H14.222C17.343 21.0001 18.904 21.0001 20.025 20.2651C20.5087 19.9481 20.9253 19.539 21.251 19.0611C22 17.9611 22 16.4281 22 13.3641C22 10.2991 22 8.76712 21.251 7.66712C20.9253 7.18923 20.5087 6.78012 20.025 6.46312C19.305 5.99012 18.403 5.82112 17.022 5.76112C16.363 5.76112 15.796 5.27112 15.667 4.63612C15.5684 4.17099 15.3123 3.75416 14.9418 3.45607C14.5714 3.15799 14.1095 2.99693 13.634 3.00012H10.366C9.378 3.00012 8.527 3.68512 8.333 4.63612C8.204 5.27112 7.637 5.76112 6.978 5.76112C5.598 5.82112 4.696 5.99112 3.975 6.46312C3.49162 6.78019 3.07537 7.1893 2.75 7.66712C2 8.76712 2 10.2991 2 13.3641C2 16.4281 2 17.9601 2.749 19.0611C3.073 19.5371 3.489 19.9461 3.975 20.2651C5.096 21.0001 6.657 21.0001 9.778 21.0001ZM16 13.0001C16 14.061 15.5786 15.0784 14.8284 15.8286C14.0783 16.5787 13.0609 17.0001 12 17.0001C10.9391 17.0001 9.92172 16.5787 9.17157 15.8286C8.42143 15.0784 8 14.061 8 13.0001C8 11.9393 8.42143 10.9218 9.17157 10.1717C9.92172 9.42155 10.9391 9.00012 12 9.00012C13.0609 9.00012 14.0783 9.42155 14.8284 10.1717C15.5786 10.9218 16 11.9393 16 13.0001ZM18 9.25012C17.8011 9.25012 17.6103 9.32914 17.4697 9.46979C17.329 9.61045 17.25 9.80121 17.25 10.0001C17.25 10.199 17.329 10.3898 17.4697 10.5305C17.6103 10.6711 17.8011 10.7501 18 10.7501H19C19.1989 10.7501 19.3897 10.6711 19.5303 10.5305C19.671 10.3898 19.75 10.199 19.75 10.0001C19.75 9.80121 19.671 9.61045 19.5303 9.46979C19.3897 9.32914 19.1989 9.25012 19 9.25012H18Z"
        fill="#96ABC4"
      />
    </svg>
  );
};
