import { formatTelegram } from './format';

export const validateTelegram = (val) => {
  if (!val || typeof val !== 'string' || val.length < 4) return false;
  val = formatTelegram(val);
  if (val.startsWith('https://t.me/+')) {
    return true;
  }
  return /^@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/.test(val);
};
