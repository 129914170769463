import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './ManagementModal.module.scss';
import { Close, HourglassBottom, PersonAddAlt } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';
import { useNavigate } from 'react-router-dom';
import { SidebarTabs } from '@ui/SidebarTabs/SidebarTabs';
import { useEffect, useState } from 'react';
import { Invite } from './Invite/Invite';
import { Waiting } from './Waiting/Waiting';
import { ManagementTable } from './ManagementTable/ManagementTable';
import { useDispatch, useSelector } from 'react-redux';
import { InviteModal } from './InviteModal/InviteModal';
import { api } from '@lib/api';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';
import { fetchCardsPage, fetchManagement } from '@features/club/store/club';
import { usePageTitle } from '@hooks/usePageTitle';
import { Payment } from './Payment/Payment';
import { ConfirmModal } from '../../../../components/ui/ConfirmModal/ConfirmModal';
import { PaymentIcon } from '../../../../icons/PaymentIcon';
import { AddUserIcon } from '../../../../icons/AddUserIcon';
import { WaitingIcon } from '../../../../icons/WaitingIcon';

export const ManagementModal = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(window.location.hash.slice(1) || 'invite');
  const { management } = useSelector((state) => state.club);
  const [checked, setChecked] = useState([]);
  const [showInvite, setShowInvite] = useState(false);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);
  const [showSuccessVerify, setShowSuccessVerify] = useState(false);
  const [showSuccessDecline, setShowSuccessDecline] = useState(false);
  const { community } = useSelector((state) => state.community);
  const pageTitle = usePageTitle();
  const [showFriendConfirm, setShowFriendConfirm] = useState(false);

  let residents = {
    invite: management.toInvite,
    waiting: management.toVerify,
    payment: management.toPay,
  }[tab];

  pageTitle('Управление резидентами');

  const dispatch = useDispatch();

  const tabs = [
    {
      name: 'invite',
      title: 'Пригласить на платформу',
      icon: AddUserIcon,
      count: management.toInvite?.length || 0,
    },
    {
      name: 'waiting',
      title: 'Ожидают подтверждения',
      icon: WaitingIcon,
      count: management.toVerify?.length || 0,
    },
  ];

  if (community.isPaid) {
    tabs.push({
      name: 'payment',
      title: 'Ожидается оплата',
      icon: PaymentIcon,
      count: management.toPay?.length || 0,
    });
  }

  const closeModal = () => {
    navigate('/club/');
  };

  const invite = async () => {
    const cardsIds = [...checked];
    await api.post(`/dashboard/club/invite/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    setShowSuccessInvite(true);
    setShowInvite(false);
  };

  const verify = async () => {
    const cardsIds = [...checked];
    await api.put(`/dashboard/club/verify/`, {
      cardsIds,
      accept: true,
    });
    dispatch(fetchManagement());
    dispatch(fetchCardsPage());
    setShowSuccessVerify(true);
  };

  const decline = async () => {
    const cardsIds = [...checked];
    await api.put(`/dashboard/club/verify/`, {
      cardsIds,
      accept: false,
    });
    setChecked([]);
    dispatch(fetchManagement());
    dispatch(fetchCardsPage());
    setShowSuccessDecline(true);
  };

  const setFriend = async () => {
    const cardsIds = [...checked];
    setChecked([]);
    await api.post(`/v2/dashboard/club/cards/friends/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    dispatch(fetchCardsPage());
    setShowFriendConfirm(false);
  };

  useEffect(() => {
    window.location.hash = tab;
  }, [tab]);

  return (
    <>
      <Dialog open={true} onClose={closeModal} className="wide-dialog">
        <DialogTitle>
          Управление резидентами
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '1100px', overflowX: 'hidden', minHeight: '400px' }}
          className="paddingless"
        >
          <div className={styles.modal}>
            <div className={styles.tabs}>
              <SidebarTabs tabs={tabs} value={tab} onChange={setTab} />
            </div>
            <div className={styles.content}>
              <div className={styles.header}>
                {tab === 'invite' && <Invite />}
                {tab === 'waiting' && <Waiting />}
                {tab === 'payment' && <Payment />}
              </div>
              <div className={styles.table}>
                <ManagementTable
                  showPaymentLink={tab === 'payment'}
                  onCheck={setChecked}
                  residents={residents}
                  tab={tab}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.footer}>
          {tab === 'invite' && (
            <AppButton
              color="primary"
              disabled={!checked.length}
              onClick={() => setShowInvite(true)}
            >
              Пригласить на платформу
            </AppButton>
          )}
          {tab === 'waiting' && (
            <>
              <AppButton
                color="secondary"
                disabled={!checked.length}
                onClick={decline}
              >
                Отклонить запрос
              </AppButton>
              <AppButton
                color="primary"
                disabled={!checked.length}
                onClick={verify}
              >
                Подтвердить
              </AppButton>
            </>
          )}
          {tab === 'payment' && (
            <>
              <AppButton
                variant="contained"
                color="primary"
                disabled={!checked.length}
                onClick={() => setShowFriendConfirm(true)}
              >
                Назначить другом сообщества
              </AppButton>
              <AppButton
                color="error"
                variant="text"
                disabled={!checked.length}
                onClick={decline}
              >
                Отклонить запрос
              </AppButton>
            </>
          )}
        </DialogActions>
      </Dialog>
      {showInvite && (
        <InviteModal
          onCancel={() => setShowInvite(false)}
          onSend={invite}
          items={checked}
        />
      )}
      {showSuccessInvite && (
        <SuccessModal
          description="Приглашения отправлены."
          onClose={() => setShowSuccessInvite(false)}
        />
      )}
      {showSuccessVerify && (
        <SuccessModal
          description="Пользователь верифицирован."
          onClose={() => setShowSuccessVerify(false)}
        />
      )}
      {showSuccessDecline && (
        <SuccessModal
          title="Готово"
          description="Запрос отклонен."
          buttonTitle="OK"
          onClose={() => setShowSuccessDecline(false)}
        />
      )}
      {showFriendConfirm && (
        <ConfirmModal
          title="Назначить другом сообщества?"
          description="Участник получит полный доступ к странице сообщества бесплатно. Но вы всегда сможете снять статус друга сообщества с участника в его карточке в разделе «Резидентство»."
          onAccept={setFriend}
          width={400}
          acceptTitle="Назначить другом сообщества"
          onDecline={() => setShowFriendConfirm(false)}
        />
      )}
    </>
  );
};
