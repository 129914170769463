export const AddUserIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00003 12C11.2092 12 13 10.2091 13 8C13 5.79086 11.2092 4 9.00003 4C6.79089 4 5.00003 5.79086 5.00003 8C5.00003 10.2091 6.79089 12 9.00003 12ZM16.89 18.55C17.046 18.8595 17.0306 19.2277 16.8493 19.5231C16.668 19.8185 16.3466 19.999 16 20H2.00003C1.65343 19.999 1.3321 19.8185 1.15079 19.5231C0.969481 19.2277 0.954057 18.8595 1.11003 18.55L2.55003 15.66C3.05882 14.6408 4.10092 13.9978 5.24003 14H12.76C13.8991 13.9978 14.9412 14.6408 15.45 15.66L16.89 18.55ZM20 10H22.5C22.7762 10 23 10.2239 23 10.5V11.5C23 11.7761 22.7762 12 22.5 12H20V14.5C20 14.7761 19.7762 15 19.5 15H18.5C18.2239 15 18 14.7761 18 14.5V12H15.5C15.2239 12 15 11.7761 15 11.5V10.5C15 10.2239 15.2239 10 15.5 10H18V7.5C18 7.22386 18.2239 7 18.5 7H19.5C19.7762 7 20 7.22386 20 7.5V10Z"
        fill={color}
      />
    </svg>
  );
};
