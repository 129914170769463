export const WaitingIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 7V4H18.5C18.7761 4 19 3.77614 19 3.5V2.5C19 2.22386 18.7761 2 18.5 2H5.5C5.22386 2 5 2.22386 5 2.5V3.5C5 3.77614 5.22386 4 5.5 4H6V7C5.98952 7.92514 6.40649 8.80335 7.13 9.38L10.4 12L7.13 14.62C6.40649 15.1966 5.98952 16.0749 6 17V20H5.5C5.22386 20 5 20.2239 5 20.5V21.5C5 21.7761 5.22386 22 5.5 22H18.5C18.7761 22 19 21.7761 19 21.5V20.5C19 20.2239 18.7761 20 18.5 20H18V17C17.9982 16.0889 17.5825 15.2279 16.87 14.66L13.6 12L16.87 9.38C17.5935 8.80335 18.0105 7.92514 18 7ZM16 4V7H8V4H16ZM16 17C16.0111 16.6818 15.87 16.3772 15.62 16.18L12 13.28L8.38 16.22C8.14126 16.4086 8.0014 16.6957 8 17V18H16V17Z"
        fill={color}
      />
    </svg>
  );
};
