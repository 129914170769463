import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';

export const relatedCommunitiesSlice = createSlice({
  name: 'relatedCommunities',
  initialState: {
    total: 0,
    communities: [],
    page: 1,
    loading: false,
    noMore: false,
  },
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setCommunities(state, action) {
      state.communities = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setNoMore(state, action) {
      state.noMore = action.payload;
    },
  },
});

export const fetchRelatedCommunities =
  (limit = 20) =>
  async (dispatch, getState) => {
    dispatch(relatedCommunitiesSlice.actions.setNoMore(false));
    setTimeout(async () => {
      const state = getState();
      if (!state?.community?.community?.id) return;
      dispatch(startProgress());
      const response = await api.get(
        `/communities/${state?.community?.community?.id}/related`,
        {
          params: {
            page: 1,
            limit,
          },
        }
      );
      dispatch(relatedCommunitiesSlice.actions.setPage(1));
      dispatch(relatedCommunitiesSlice.actions.setTotal(response.data.total));
      dispatch(
        relatedCommunitiesSlice.actions.setCommunities(response.data.data)
      );
    }, 100);
  };

export const fetchRelatedPage = () => async (dispatch, getState) => {
  dispatch(relatedCommunitiesSlice.actions.setLoading(true));
  dispatch(startProgress());
  const state = getState();
  const newPage = state.relatedCommunities.page + 1;
  const response = await api.get(
    `/communities/${state.community.community.id}/related`,
    {
      params: {
        page: newPage,
        limit: 20,
      },
    }
  );
  dispatch(relatedCommunitiesSlice.actions.setPage(newPage));
  dispatch(relatedCommunitiesSlice.actions.setTotal(response.data.total));
  dispatch(
    relatedCommunitiesSlice.actions.setCommunities([
      ...state.relatedCommunities.communities,
      ...response.data.data,
    ])
  );
  if (!response.data.data.length) {
    dispatch(relatedCommunitiesSlice.actions.setNoMore(true));
  }
  dispatch(relatedCommunitiesSlice.actions.setLoading(false));
};

export const removeRelatedCommunity =
  (communityId) => async (dispatch, getState) => {
    const {
      relatedCommunities: { communities },
    } = getState();
    dispatch(startProgress());
    await api.delete(`/communities/related/${communityId}`);
    dispatch(
      relatedCommunitiesSlice.actions.setCommunities(
        communities.filter((com) => com.id !== communityId)
      )
    );
  };
