import { api } from '@lib/api';
import { mnt } from '../../../util/moment';
import { range, sortBy } from 'lodash';
import { formatTelegram } from '../../../util/format';

export const handleCards = (cards) => {
  return cards.map((card) => ({
    ...card,
    updatedAt:
      typeof card.updatedAt === 'number'
        ? card.updatedAt
        : mnt(card.updatedAt).toDate().getTime(),
  }));
};

export const fetchCards = async (params) => {
  const {
    data: { total, data },
  } = await api.get(`/v2/dashboard/club/cards/`, {
    params,
  });
  return {
    total,
    cards: handleCards(data),
    params,
  };
};

export const fetchPagesRange = async (currentParams) => {
  const currentPage = currentParams.page;
  let cards = [];
  let total = 0;
  let params = {
    ...currentParams,
  };
  const pageNumbers = range(1, (currentPage || 1) + 1);
  for (let page of pageNumbers) {
    params = {
      ...currentParams,
      page,
    };
    const data = await fetchCards(params);
    total = data.total;
    cards = [...cards, ...data.cards];
  }
  return { cards, total: total, params };
};

export const sortCards = (cards) => {
  const items = sortBy(cards, 'lastOrderAt', 'profileId');
  return items;
};

export const prepareParams = ({
  page,
  limit,
  search,
  isArchive,
  filters = {},
}) => {
  const params = {
    page: page,
    limit: limit,
    search,
    isArchive,
    memberCondition: 'active',
  };
  if (filters.city) {
    params.city = filters.city;
  }
  if (filters.community) {
    params.community = filters.community;
  }
  if (filters.role) {
    params.role = filters.role;
  }
  if (filters.status) {
    params.status = filters.status;
  }
  if (filters.onlyMainCommunity) {
    params.onlyMainCommunity = filters.onlyMainCommunity;
  }
  return params;
};

export const updateCardInArray = (cards, cardId, common) => {
  const items = [...cards];
  const index = items.findIndex((item) => item.id === cardId);
  if (index < 0) return;
  const item = { ...items[index] };
  item.firstName = common.about.firstName;
  item.lastName = common.about.lastName;
  item.actualRequest = common.about.actualRequest || 'Не указан';
  item.email = common.contacts.emails[0];
  item.phone = common.contacts.phones[0];
  item.telegram = formatTelegram(common.contacts.telegrams[0]);
  items.splice(index, 1, item);
  return items;
};

export const updateCardsList = (currentCards, newCards) => {
  let cards = [...currentCards];
  const toAdd = [];
  const toRemove = [];
  newCards.forEach((newCard) => {
    const index = cards.findIndex((card) => card.id === newCard.id);
    if (index < 0) {
      return toAdd.push(newCard);
    }
    const existingCard = cards[index];
    cards.splice(index, 1, {
      ...existingCard,
      ...newCard,
    });
  });
  cards.forEach((existingCard) => {
    const index = newCards.findIndex(
      (newCard) => newCard.id === existingCard.id
    );
    if (index < 0) {
      toRemove.push(existingCard);
    }
  });
  toAdd.forEach((newCard) => {
    cards.push(newCard);
  });
  toRemove.forEach((existingCard) => {
    cards.splice(cards.indexOf(existingCard), 1);
  });
  return cards;
};
