import { MoreHoriz } from '@mui/icons-material';
import { AppTag } from '../../AppTag/AppTag';
import styles from './Options.module.scss';
import { createPortal } from 'react-dom';
import { StatusSettings } from '../StatusSettings/StatusSettings';
import { useRef, useState } from 'react';

export const Options = ({
  options,
  onClick,
  onOptionChange,
  onOptionRemove,
  search,
  onOptionCreate,
  heightShift,
  disableCreate,
  allOptions,
}) => {
  const itemsRef = useRef();
  const [tag, setTag] = useState(null);
  const [settingsCoords, setSettingsCoords] = useState([0, 0]);

  const canCreate =
    !disableCreate &&
    !!search?.trim() &&
    !options.map((option) => option.trim()).includes(search.trim());

  const optionClicked = (ev, option) => {
    ev.stopPropagation();
    onClick && onClick(option);
    setTag(null);
    setSettingsCoords([0, 0]);
  };

  const getOptionCoords = (option) => {
    const elem = document.querySelector(`[data-status-option="${option}"]`);
    const b = elem.getBoundingClientRect();
    return [b.x + b.width - 23, b.y + 12];
  };

  const buttonClicked = (ev, option) => {
    ev.stopPropagation();
    setTag(option);
    setSettingsCoords(getOptionCoords(option));
  };

  const closeSettings = () => {
    setTag(null);
    setSettingsCoords([0, 0]);
  };

  const optionChanged = (newValue) => {
    onOptionChange && onOptionChange(tag, newValue);
    setTag(newValue);
  };

  const removeOption = () => {
    onOptionRemove && onOptionRemove(tag);
    setTag(null);
  };

  const createOption = (ev) => {
    ev.stopPropagation();
    onOptionCreate && onOptionCreate(search);
  };

  const scrollItems = (ev) => {
    const elem = itemsRef.current;
    elem.scrollTop += ev.deltaY;
  };

  return (
    <>
      <div
        className={styles.options}
        style={{ top: `${90 + heightShift}px` }}
        data-create={canCreate ? 'true' : 'false'}
        data-empty={!canCreate && !options.length ? 'empty' : ''}
      >
        <div className={styles.item + ' ' + styles.tooltip}>
          Выберите из списка или создайте новый тег
        </div>
        <div className={styles.items} onWheel={scrollItems} ref={itemsRef}>
          {options.map((option) => (
            <div
              className={styles.item}
              key={option}
              onClick={(ev) => optionClicked(ev, option)}
              data-status-option={option}
              data-active={tag === option ? 'active' : ''}
            >
              <div className={styles.tag}>
                <AppTag
                  marginless
                  label={option}
                  onClick={(ev) => optionClicked(ev, option)}
                />
              </div>
              <div
                className={styles.button}
                onClick={(ev) => buttonClicked(ev, option)}
              >
                <MoreHoriz />
              </div>
            </div>
          ))}
        </div>
        {canCreate && (
          <div
            className={styles.item + ' ' + styles.create}
            onClick={createOption}
          >
            <div className={styles.label}>Создать</div>
            <div className={styles.tag}>
              <AppTag label={search} onClick={createOption} marginless />
            </div>
          </div>
        )}
      </div>
      {tag &&
        createPortal(
          <StatusSettings
            tag={tag}
            onChange={optionChanged}
            coords={settingsCoords}
            onClose={closeSettings}
            onRemove={removeOption}
            allOptions={allOptions}
          />,
          document.body
        )}
    </>
  );
};
