export const CommunityResourcesIcon = () => {
  return (
    <svg
      width="187"
      height="124"
      viewBox="0 0 187 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="187" height="124" rx="4" fill="#F7FAFB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6239 31.023C76.6239 28.8011 78.4044 27 80.6006 27H106.401C108.597 27 110.377 28.8012 110.377 31.023V40.6585H131.715C133.911 40.6585 135.692 42.4597 135.692 44.6815V92.977C135.692 95.1988 133.911 97 131.715 97H55.2849C53.0887 97 51.3082 95.1988 51.3082 92.977V44.6815C51.3082 42.4597 53.0887 40.6585 55.2849 40.6585H76.6239V31.023ZM107.002 40.6585H79.9995V32.4263C79.9995 31.3154 80.8897 30.4148 81.9878 30.4148H105.014C106.112 30.4148 107.002 31.3154 107.002 32.4263V40.6585Z"
        fill="#CFEAF9"
      />
    </svg>
  );
};
