import { SectionHeader } from '../../ResidentModal/SectionHeader/SectionHeader';
import styles from './Waiting.module.scss';

export const Waiting = () => {
  return (
    <div className={styles.waiting}>
      <SectionHeader
        title="Ожидают подтверждения"
        description="Проверьте данные участников. После вашего подтверждения  мы отправим каждому одобренному участнику СМС со ссылкой на оплату. Доступ к странице вашего сообщества они получат только после завершения оплаты."
      />
    </div>
  );
};
